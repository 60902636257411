'use client';

import React from "react";
import { Button, Menu, Popover } from "@mantine/core";
import { usePathname, useRouter } from 'next/navigation'
import themeVariables from "@/config/theme/themeVariable";
import Link from "next/link";
import { images } from "@/utils/images";
import { useAtomValue } from "jotai";
import * as atoms from '@/config/store/globalStore'
import { ChevronDownIcon, Cog8ToothIcon, IdentificationIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import { handleSignOut } from "@/utils/auth";
import { Link as ScrollLink } from "react-scroll";
import { useDisclosure } from "@mantine/hooks";
import { FeatureRequestModal } from "@/components/FeatureInquiryModal";
import { CreditImage } from "@/components/CreditIcon";
import { FEATURE_ITEMS, MENU_ITEMS } from "@/utils/menus";
import { FeatureType } from "@/config/endpoint/types/feature";



export default function NavigationBar() {
  const { push } = useRouter();
  const pathname = usePathname();
  const userInfo = useAtomValue(atoms.userInfo);
  const [opened, { close, open }] = useDisclosure(false);


  
  const MobileNavbar = () => {
    const signIn = useAtomValue(atoms.signIn)

    const MobileLoginButton = () => (
      <div className="flex gap-1 items-center ml-auto pr-2">
          { signIn && !pathname.startsWith('/dashboard')
            &&  <Link href={`/dashboard/${FeatureType.SketchRendering}`}>
                  <Button classNames={{
                    root: `${themeVariables.button.dark.root} !h-8`,
                    label: `${themeVariables.button.dark.label} text-[13px]`,
                  }}>
                    대시보드
                  </Button>
                </Link>
          }
          { !signIn
            ? <Link href={'/login'}>
                <Button classNames={{
                  root: `${themeVariables.button.dark.root} !h-8`,
                  label: `${themeVariables.button.dark.label} text-[13px]`,
                }}>
                  로그인
                </Button>
              </Link>
            :  <Link href={'/my-account'}>
                <Button
                  classNames={{
                    root: `${themeVariables.button.transparent.root} !h-8 !px-2`,
                    label: `${themeVariables.button.transparent.label} text-[13px]`,
                  }}
                >
                  {userInfo.name}님
                </Button>
              </Link>
          }
        </div>
    )


    return (
      <nav className="z-[100] w-full flex md:hidden sticky h-12 items-center top-0 bg-white">
        <Link href={'/'} className="flex gap-1.5 items-center px-4" scroll={false}>
          <img className="w-7 h-7" src={images.logo}/>
          <div className="font-bold text-sm text-gray-900">디스페이스</div>
        </Link>
        { signIn == null ? <div className="w-[90px]"/> : <MobileLoginButton/>}
      </nav>
    )
  }

  const DesktopNavBar = () => (
    <nav className="z-[100] w-full hidden md:flex h-auto items-center justify-center data-[menu-open=true]:border-none sticky top-0 inset-x-0 border-b border-divider backdrop-blur-lg data-[menu-open=true]:backdrop-blur-lg backdrop-saturate-150 bg-white/75">
      <header className="z-[100] flex gap-4 w-full flex-row relative flex-nowrap items-center justify-between h-16 max-w-[1280px]">
        <div className="h-full w-full px-6 flex flex-row flex-nowrap items-center justify-between gap-6">
          <div
            className="flex gap-2 align-middle items-center cursor-pointer w-[160px]"
            onClick={() => push('/')}
          >
            <img className="w-9 h-9" src={images.logo}/>
            <div className="font-bold text-base">디스페이스</div>
          </div>
          <div className={`flex gap-4 ${pathname.startsWith('/dashboard') ? 'hidden' : ''}`}>
            <Popover offset={14} opened={opened} radius={'lg'} shadow={'sm'} width={200} position={'bottom-end'} zIndex={101} onClose={close} closeOnClickOutside clickOutsideEvents={['mouseup', 'touchend']}>
              <Popover.Target>
                <div onClick={open} className="flex gap-1 items-center justify-center hover:font-semibold hover:text-gray-700 text-gray-600 cursor-pointer">
                  <div className="p-2.5 rounded-lg text-base">
                    {'기능 살펴보기'}
                  </div>
                  <ChevronDownIcon className="w-4 h-4 stroke-[1.5px]"/>
                </div>
              </Popover.Target>
              <Popover.Dropdown classNames={{ dropdown: '!px-1.5 !py-1.5'}}>
                {FEATURE_ITEMS.map(item => (
                  <ScrollLink
                    key={item.id}
                    to={item.id}
                    activeClass='font-semibold text-gray-800'
                    spy={true}
                    smooth={true}
                    onClick={() => {
                      close();
                      if (pathname != '/') {
                        push(`/#${item.id}`)
                      }
                    }}
                  >
                    <div className="px-3 py-2.5 my-1 rounded-lg text-base text-gray-600 cursor-pointer hover:bg-gray-50">
                      {item.label}
                    </div>
                  </ScrollLink>
                ))}
              </Popover.Dropdown>
            </Popover>
            {MENU_ITEMS.map((item) => (
              <ScrollLink
                key={item.id}
                to={item.id}
                activeClass='font-semibold text-gray-800'
                spy={true}
                smooth={true}
                onClick={() => {
                  if (pathname != '/') {
                    push(`/#${item.id}`)
                  }
                }}
              >
                <div className="p-2.5 rounded-lg text-base text-gray-600 cursor-pointer hover:font-semibold hover:text-gray-700">
                  {item.label}
                </div>
              </ScrollLink>
            ))}
          </div>
          <LoginButton/>
        </div>
      </header>
    </nav>
  )


  return (
    <>
      <MobileNavbar/>
      <DesktopNavBar/>
    </>
  );
}

const LoginButton = () => {
  const userInfo = useAtomValue(atoms.userInfo);
  const signIn = useAtomValue(atoms.signIn);
  const { push } = useRouter();
  const pathname = usePathname();

  if (signIn == null) {
    return <div className="w-[300px]"/>
  }
  
  if (!signIn) {
    return (
      <Link href="/login" className="w-[300px]">
        <Button classNames={themeVariables.button.dark}>
          로그인
        </Button>
      </Link>
    )
  }

  return (
    <div className="flex gap-1.5 items-center w-[300px] justify-end">
      { signIn && !pathname.startsWith('/dashboard')
        ? <Link href={`/dashboard/${FeatureType.SketchRendering}`}>
            <Button classNames={themeVariables.button.dark}>
              대시보드
            </Button>
          </Link>
        : <FeatureRequestModal/>
      }
      <Menu classNames={{ item: '!py-2'}} shadow={'sm'} trigger={'click-hover'} width={180} position={'bottom-end'} zIndex={101}>
        <Menu.Target>
          <div className="flex justify-end min-w-fit md:!ml-4 max-w-[160px] line-clamp-1 items-center font-normal text-[15px] text-gray-700 cursor-pointer">
            <CreditImage text={userInfo.credit}/>
            <div className="flex gap-2 items-center ml-3 leading-loose">
              {userInfo.name}님
              <ChevronDownIcon className="w-3.5 h-3.5 stroke-[3px] text-gray-600"/>
            </div>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<Cog8ToothIcon className="w-4 h-4 text-gray-700"/>}
            onClick={() => push('/my-account/profile')}
          >
            계정 설정
          </Menu.Item>
          <Menu.Item 
            leftSection={<IdentificationIcon className="w-4 h-4 text-gray-700"/>}
            onClick={() => push('/my-account/membership')}
          >
            멤버쉽 정보
          </Menu.Item>
          <Menu.Item
            leftSection={<ReceiptPercentIcon className="w-4 h-4 text-gray-700"/>}
            onClick={() => push('/my-account/invitation')}
          >
            추천인 코드
          </Menu.Item>
          <Menu.Item
            leftSection={<ArrowLeftOnRectangleIcon className="w-4 h-4 text-gray-700"/>}
            onClick={async () => {
              await handleSignOut();
            }}
          >
            로그아웃
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}